/*
 * @Author: liyu
 * @Date: 2022-06-07 16:05:40
 * @LastEditTime: 2022-06-08 15:24:07
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/trust/security/index.tsx
 */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { TrustCenterState } from '../center/index.en'
import { CommonContentList } from 'components/Trust/Card/CommonCard'
import SectionContent from 'components/SectionContent'
import * as styles from './index.module.less'
import Layout from 'layouts/en'

const Security: React.FC = (props) => {
  const { trustSecurityEnYaml } = useStaticQuery(
    graphql`
      query {
        trustSecurityEnYaml {
          security {
            title
            description
            key
            satitle
          }
          banner_bg_pc
          banner_bg_mb
          security_banner_info {
            content
            title
          }
          security_tab_list {
            is_active
            link
            name
          }
          bottom_card_title
          bottom_card_desc
          security_content_info {
            title
            small_icon
          }
          security_content_list {
            content {
              content
              title
            }
            icon
            title
          }
        }
      }
    `,
  )

  const { security, security_tab_list, security_content_info, security_content_list, security_banner_info } =
    trustSecurityEnYaml

  return (
    <Layout {...props}>
      <TrustCenterState
        trustCenterEnYaml={trustSecurityEnYaml}
        TDK={security}
        bannerInfo={security_banner_info}
        tabList={security_tab_list}
      >
        <>
          <SectionContent className={styles.security} title={security_content_info.title}>
            <CommonContentList data={security_content_list} smallIcon={security_content_info.small_icon} />
          </SectionContent>
        </>
      </TrustCenterState>
    </Layout>
  )
}

export default Security
